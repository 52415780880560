// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from "firebase/functions";
import { Capacitor } from "@capacitor/core";
import {
  getAuth,
  initializeAuth,
  indexedDBLocalPersistence,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";

import { getMessaging, onMessage } from "firebase/messaging";

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAWyMK2DXlNrNlXihzjnp_oj4P81u1vTXk",
  authDomain: "tomtutreino.firebaseapp.com",
  databaseURL: "https://tomtutreino-default-rtdb.firebaseio.com",
  projectId: "tomtutreino",
  storageBucket: "tomtutreino.appspot.com",
  messagingSenderId: "735492675586",
  appId: "1:735492675586:web:fe683aa6e300423f11af1b",
  measurementId: "G-6V6XGDBXPC",
};

// Initialize Firebase

function whichAuth() {
  let auth;
  if (Capacitor.isNativePlatform()) {
    auth = initializeAuth(app, {
      persistence: indexedDBLocalPersistence,
    });
  } else {
    auth = getAuth();
  }
  return auth;
}

const app = initializeApp(firebaseConfig);
const auth = whichAuth();
const analytics = getAnalytics(app);
const functions = getFunctions(app);
const db = getFirestore(app);

const messaging = getMessaging(app);

export default {
  app,
  analytics,
  functions,
  auth,
  db,
  messaging,
};
