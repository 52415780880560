import {
  getDatabase,
  ref as dbRef,
  set,
  onDisconnect,
} from "firebase/database";

/**
 * Set a user as online and attach onDisconnect logic to set them offline when they disconnect.
 * @param {string} userId - The user's ID.
 * @param {string} userName - The user's name to display in the status node.
 */
function setUserOnline(userId, userName) {
  const db = getDatabase();
  const userStatusRef = dbRef(db, `/status/${userId}`);

  // Set the user's current status to online
  set(userStatusRef, {
    name: userName,
    state: "online",
    lastChanged: Date.now(),
  });

  // Ensure that when this client disconnects, the user's status is set to offline
  onDisconnect(userStatusRef).set({
    name: userName,
    state: "offline",
    lastChanged: Date.now(),
  });
}

/**
 * Set a user as offline.
 * @param {string} userId - The user's ID.
 * @param {string} userName - The user's name.
 */
function setUserOffline(userId, userName) {
  const db = getDatabase();
  const userStatusRef = dbRef(db, `/status/${userId}`);

  // Explicitly set the user as offline. Useful when a user logs out.
  set(userStatusRef, {
    name: userName,
    state: "offline",
    lastChanged: Date.now(),
  });
}

export { setUserOnline, setUserOffline };
