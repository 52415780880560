import { getDatabase, ref as dbRef, onValue } from "firebase/database";

/**
 * Listen for unread messages for a given user and call the provided callback with the unread count.
 * @param {string} userId The ID of the user we’re listening for
 * @param {function} callback A function to call whenever unread count changes, passing the unread count as an argument
 */
function listenForUnreadCount(userId, callback) {
  const db = getDatabase();
  const conversationRef = dbRef(db, `interactions/${userId}`);

  onValue(conversationRef, (snapshot) => {
    const data = snapshot.val();
    let unreadCount = 0;

    // Check if there is a last message from the admin and if it's after lastSeenUser
    if (
      data &&
      data.lastMessageFromUser === false &&
      data.lastMessageDate > (data.lastSeenUser || 0)
    ) {
      unreadCount = 1;
    }

    // Pass the unread count to the callback so you can update your badge
    callback(unreadCount);
  });
}

export { listenForUnreadCount };
